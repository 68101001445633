import { useState } from "react";
import { animated, useSpring } from '@react-spring/web';
// import { DataContext } from "./ammoOP";
import '../styles/mobileDrawer.css';


export default function MobileDrawer() : React.ReactElement {
    const [tooSmall, setTooSmall] = useState<boolean>(false);
    const [isMobileDrawerShowing, setIsMobileDrawerShowing] = useState<boolean>(false);

    // const {isMobileDrawerShowing, setIsMobileDrawerShowing} = useContext(DataContext)
    // const isMobileDrawerShowing = dataContext.isMobileDrawerShowing
    // const setIsMobileDrawerShowing = dataContext.setIsMobileDrawerShowing
    
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const containerWidth = screenWidth*0.75

    if ((screenWidth < 1250 || screenHeight < 700) && !tooSmall) {
        setTooSmall(true);
    }
    else if (screenWidth > 1250 && screenHeight > 700 && tooSmall) {
        setTooSmall(false);
    }

    const springs = useSpring({
        x: isMobileDrawerShowing ? 0 : -containerWidth,
        zIndex: 9
    })
    const buttonSprings = useSpring({
        x: isMobileDrawerShowing ? containerWidth : 0,
        zIndex: 10
    })
    const handleClick = () => {
        setIsMobileDrawerShowing(!isMobileDrawerShowing)
    }
    const drawerButton = () => {
        if (isMobileDrawerShowing){
            return(
                <button className="mobileInfoDrawerButton" onClick={handleClick}><span>{"< INFO"}</span></button>
            )
        } else {
            return(
                <button className="mobileInfoDrawerButton" onClick={handleClick}><span>{"INFO >"}</span></button>
            )
        }
    }

    if (tooSmall){
        return(
            <>
                <animated.div
                    className="mobileInfoDrawerContainer"
                    style={{
                        width: containerWidth,
                        ...springs,
                    }}
                >
                    <div className="mobileInfoDrawerDescriptionContainer">
                        <div className="descriptionInnerBox">
                            <div className="descriptionHeader">
                                <div className="ammoop">What is AmmoOp?</div>
                            </div>
                                <br/>
                            <div style={{fontWeight: "bold"}}>*<div style ={{color: "red"}}>This Project is in a TESTING PHASE. Any issues or bugs found should be detailed in an 
                                email to the project manager found at the "contact us" link at the bottom of the webpage.</div>*
                            </div>
                                <br/>
                            <div>AmmoOp is designed to simplify the process of ordering ammunition for individuals of any rank or position. It automatically 
                                calculates all ammunition requirements based on the latest STRAC for the chosen event or series of events. In upcoming updates, 
                                we plan to introduce a feature that allows users to create custom live fire ranges. For now, we hope you find value in the 
                                current version, saving you both time and reducing stress. Enjoy using AmmoOp!
                            </div>
                                <br/>
                            <div className="linkdiv">
                                <a href="https://www.dau.edu/sites/default/files/Migrated/CopDocuments/CAPULDI%202009.pdf"
                                target="_blank">CAPULDI 2009</a><br/>
                                <a href="https://www.dau.edu/sites/default/files/2023-11/CAPULDI%20excel%2030%20November%202023.xlsx"
                                target="_blank">CAPULDI 2023 EXCEL</a><br/>
                                <a href="https://www.dau.edu/cop/ammo/page/AEResearchTools"
                                target="_blank">YELLOW BOOK</a>
                            </div>
                        </div>
                    </div>
                    
                </animated.div>
                <animated.div 
                    style={{
                        width: 'fit-content',
                        ...buttonSprings,
                    }}
                >
                    {drawerButton()}
                </animated.div>
            </>
        )   
    } else {
        return(
            <></>
        )
    }
    
}