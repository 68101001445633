import { ARMY_DODICS, MAX_PAGES, MODAL_STYLE_FIT_CONTENT, STR_PROJECT_MANAGER_EMAIL, STR_PROJECT_MANAGER_INFO } from "../constants";
import Modal from 'react-modal';
import { ReactElement, useState, useEffect, createContext } from "react";
import { ALL_CHAPTERS } from "../constants"
import { ALL_CATEGORIES } from "../constants";
import { ICategory, IChapter, IDataContext, IStrategy } from "../interfaces"
import { Button, IconButton, Collapse, Box, List, ListItem } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select/creatable'
import CompressIcon from '@mui/icons-material/Compress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
// import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import Iterator from "./iterator2";
import { components } from "react-select";
import { createFunctionalTextFields, createNonFunctionalFields, createSecondaryPagesTextFields } from "./pdfConstruction";
import { PDFDocument, StandardFonts, PDFForm, PDFPage } from 'pdf-lib';
import fontKit from '@pdf-lib/fontkit';
import MobileDrawer from "./MobileDrawer";

export const DataContext = createContext<IDataContext>({} as IDataContext);

function AmmoOP(): ReactElement {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [is10Added, set10Added] = useState<boolean>(false);
    const [isEventsMulti, setIsEventsMulti] = useState<boolean>(false);
    const [chapterSelected, setChapterSelected] = useState<string>();
    const [pageSubtitle, setPageSubtitle] = useState<string[]>([]);
    const [weaponTags, setWeaponTags] = useState<string[]>([]);
    const [eventTags, setEventTags] = useState<string[]>([]);
    const [weaponValue, setWeaponValue] = useState<Option | null>(null);
    const [eventsValue, setEventsValue] = useState<Option | null>(null);
    const [catValue, setCatValue] = useState<Option | null>(null);
    const [eveValue, setEveValue] = useState<readonly Option[]>([]);
    const [weaponCount, setweaponCount] = useState<number>(1);
    const [iterationCount, setIterationCount] = useState<number>(1);
    const [savedCalcs, setSavedCalcs] = useState<{ [charRep : string]: IStrategy[] }[]>([]);
    const [savedTitles, setSavedTitles] = useState<ReactElement[]>([]);
    const [tempWeaponValue, setTempWeaponValue] = useState<Option | null>(null);
    const [tempEventsValue, setTempEventsValue] = useState<Option | null>(null);
    const [tempCatValue, setTempCatValue] = useState<Option | null>(null);
    const [tempEveValue, setTempEveValue] = useState<readonly Option[]>([]);
    const [open, setOpen] = useState<boolean>(true);
    const [quickSetClicked, setQuickSetClicked] = useState<boolean>(false);
    const [tooSmall, setTooSmall] = useState<boolean>(false);
    const [tooTall, setTooTall] = useState<boolean>(false);
    const [tooShort, setTooShort] = useState<boolean>(false);
    const [tooNarrow, setTooNarrow] = useState<boolean>(false);
    // const [isMobileDrawerShowing, setIsMobileDrawerShowing] = useState<boolean>(false);

    // const dataContextValue = {
    //     isMobileDrawerShowing : [isMobileDrawerShowing],
    //     setIsMobileDrawerShowing : setIsMobileDrawerShowing
    // }
    interface Option {
        readonly label: string;
        readonly value: string;
    }

    /**
     * Creates a list of weapon tags based on the selected chapter.
     * @returns An array of strings representing weapon tags.
     */
    function createWeaponTagList(): string[] {
        // Initialize an empty array to store weapon tags.
        const allWeaponTags: string[] = [];
        // Iterate through all categories.
        for (let i = 0; i < ALL_CATEGORIES.length; i++){
            // Iterate through chapters within the current category.
            for ( let k = 0; k < ALL_CATEGORIES[i].chapters.length; k++){
                // Check if the current chapter matches the selected chapter.
                if (ALL_CATEGORIES[i].chapters[k] === chapterSelected){
                    // Iterate through tags within the current category.
                    for (let x = 0; x < ALL_CATEGORIES[i].tags.length; x++){
                        // Check if the tag is "Break" or "EventType" and break the loop if it is.
                        if (ALL_CATEGORIES[i].tags[x] === "Break") break;
                        else if (ALL_CATEGORIES[i].tags[x] === "EventType") break;
                        // Check if the tag is not "Weapon," does not include "Weapons," and is not already in the list.
                        else if (ALL_CATEGORIES[i].tags[x] !== "Weapon" && !ALL_CATEGORIES[i].tags[x].includes("Weapons") && !allWeaponTags.includes(ALL_CATEGORIES[i].tags[x]))
                            // Add the tag to the list of weapon tags.
                            allWeaponTags.push(ALL_CATEGORIES[i].tags[x]);
                    }
                    // Break the loop since the selected category has been processed.
                    break;
                }
            }
        }
        // Return the final list of weapon tags.
        return(allWeaponTags);
    }

    /**
     * Creates a list of event tags based on the selected chapter and optional weapon value.
     * @returns An array of strings representing event tags.
     */
    function createEventTagList(): string[] {
        // Initialize an empty array to store event tags.
        const allEventTags: string[] = [];
        // Check if quickSetClicked is false, and reset the events value if true.
        if (!quickSetClicked) setEventsValue(null);
        // Check if weaponValue is falsy (null, undefined, etc.)
        if(!weaponValue)
        // Iterate through all categories.
        for ( let i = 0; i < ALL_CATEGORIES.length; i++){
            // Iterate through chapters within the current category.
            for ( let k = 0; k < ALL_CATEGORIES[i].chapters.length; k++){
                // Check if the current chapter matches the selected chapter.
                if (ALL_CATEGORIES[i].chapters[k] === chapterSelected){
                    // Iterate through tags within the current category.
                    for (let x = 0; x < ALL_CATEGORIES[i].tags.length; x++){
                        // Check if the tag is "EventType."
                        if (ALL_CATEGORIES[i].tags[x] === "EventType"){
                            // Iterate from the current tag index to the end of the tags array.
                            for (let y = x; y < ALL_CATEGORIES[i].tags.length; y++){
                                // Check if the tag is not "Break," not "EventType," and not already in the list.
                                if(ALL_CATEGORIES[i].tags[y] !== "Break" && ALL_CATEGORIES[i].tags[y] !== "EventType" && !allEventTags.includes(ALL_CATEGORIES[i].tags[y]))
                                    // Add the tag to the list of event tags.
                                    allEventTags.push(ALL_CATEGORIES[i].tags[y]);
                            }
                            // Break the loop since the relevant tags have been processed.
                            break;
                        }
                    }
                    // Break the loop since the selected category has been processed.
                    break;
                }
            }
        }
        else
        // Iterate through all categories.
        for ( let i = 0; i < ALL_CATEGORIES.length; i++){
            // Iterate through chapters within the current category.
            for ( let k = 0; k < ALL_CATEGORIES[i].chapters.length; k++){
                // Check if the current chapter matches the selected chapter.
                if (ALL_CATEGORIES[i].chapters[k] === chapterSelected){
                    // Iterate through catOptions.
                    for ( let j = 0; j < catOptions.length; j++)
                        // Check if the current category's tags include the label of the selected weapon.
                        if (ALL_CATEGORIES[i].tags.includes(weaponValue.label))
                            // Iterate through tags within the current category.
                            for (let x = 0; x < ALL_CATEGORIES[i].tags.length; x++){
                                 // Check if the tag is "EventType."
                                if (ALL_CATEGORIES[i].tags[x] === "EventType"){
                                    // Iterate from the current tag index to the end of the tags array.
                                    for (let y = x; y < ALL_CATEGORIES[i].tags.length; y++){
                                        // Check if the tag is not "Break," not "EventType," and not already in the list.
                                        if(ALL_CATEGORIES[i].tags[y] !== "Break" && ALL_CATEGORIES[i].tags[y] !== "EventType" && !allEventTags.includes(ALL_CATEGORIES[i].tags[y]))
                                            // Add the tag to the list of event tags.
                                            allEventTags.push(ALL_CATEGORIES[i].tags[y]);
                                    }
                                    // Break the loop since the relevant tags have been processed.
                                    break;
                                }
                            }
                     // Break the loop since the selected category has been processed.
                    break;
                }
            }
        }
        // Return the final list of event tags.
        return(allEventTags);
    }

    /**
     * Creates a list of options for weapons based on the provided weapon tags.
     * @returns An array of objects representing weapon options with 'value' and 'label' properties.
     */
    function createWeaponsList() {
        const weaponOptions = [];
        for (let i = 0; i < weaponTags.length; i++){
            weaponOptions.push({value: weaponTags[i], label: weaponTags[i]});
        }
        return weaponOptions;
    }

    /**
     * Creates a list of options for events based on the provided events tags.
     * @returns An array of objects representing event options with 'value' and 'label' properties.
     */
    function createEventTagsList() {
        const eventOptions = [];
        for (let i = 0; i < eventTags.length; i++){
            eventOptions.push({value: eventTags[i], label: eventTags[i]});
        }
        return eventOptions;
    }
    
    /**
     * Creates a list of options for categories based on the selected chapter, weapon value, and events value.
     * @returns An array of objects representing category options with 'value' and 'label' properties.
     */
    function createCategoriesList() {
        // Initialize an empty array to store category options.
        const categoryOptions = [];
        // Iterate through all categories.
        for (let i = 0; i < ALL_CATEGORIES.length; i++){
            // Iterate through chapters within the current category.
            for (let x = 0; x < ALL_CATEGORIES[i].chapters.length; x++)
            // Check if the current chapter matches the selected chapter.
                if (ALL_CATEGORIES[i].chapters[x] === chapterSelected)
                // Check if either weaponValue or eventsValue is present.
                    if (weaponValue || eventsValue){
                        // Iterate through tags within the current category.
                        for (let y = 0; y < ALL_CATEGORIES[i].tags.length; y++){
                            // Check if both weaponValue and eventsValue are present.
                            if (eventsValue && weaponValue){
                                // Check if the category's tags include both weaponValue and eventsValue.
                                if (ALL_CATEGORIES[i].tags.includes(JSON.stringify(weaponValue.value).replace(/['"]+/g, '')) &&
                                    ALL_CATEGORIES[i].tags.includes(JSON.stringify(eventsValue.value).replace(/['"]+/g, ''))){
                                    // Add the category to the list of category options.
                                    categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                                    // Break the loop since the category has been processed.
                                    break;
                                }
                            }
                            // Check if only weaponValue is present.
                            if (weaponValue && !eventsValue){
                                // Check if the category's tags include the value of weaponValue.
                                if (ALL_CATEGORIES[i].tags.includes(JSON.stringify(weaponValue.value).replace(/['"]+/g, ''))){
                                    // Add the category to the list of category options.
                                    categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                                    // Break the loop since the category has been processed.
                                    break;
                                }
                            }
                            // Check if only eventsValue is present.
                            if (eventsValue && !weaponValue){
                                // Check if the category's tags include the value of eventsValue.
                                if (ALL_CATEGORIES[i].tags.includes(JSON.stringify(eventsValue.value).replace(/['"]+/g, ''))){
                                    // Add the category to the list of category options.
                                    categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                                    // Break the loop since the category has been processed.
                                    break;
                                }
                            }
                        }
                    }
                    else {
                        // Add the category to the list of category options when neither weaponValue nor eventsValue is present.
                        categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                    }
        }
        // Return the final list of category options.
        return categoryOptions;
    }

    function createEventList() {
        let category: ICategory = 
        {
            "name": "Dummy Category",
            "chapters":["DEBUG"],
            "events": [
                { "name": "You shouldn't see this", "strategies": [], "isSelected": false },
            ],
            "tags": ["DEBUG"]
        };
        for(let i = 0; i < ALL_CATEGORIES.length; i++)
            for(let x = 0; x < ALL_CATEGORIES[i].chapters.length; x++)
                if (catValue?.label === ALL_CATEGORIES[i].name && ALL_CATEGORIES[i].chapters[x] === chapterSelected){ 
                    category = ALL_CATEGORIES[i];
        }
        const eventOptions = [];
        for (let i = 0; i < category.events.length; i++) eventOptions.push({value: category.events[i].name, label: category.events[i].name});
        return eventOptions;
    }

    const [catOptions, setCatOptions] = useState(createCategoriesList());
    const [eveOptions, setEveOptions] = useState(createEventList());
    const [weaponOptions, setWeaponOptions] = useState(createWeaponsList());
    const [eventsOptions, setEventsOptions] = useState(createEventTagsList());

    useEffect(() => {
        setPageSubtitle([])
        for (let i = 0; i < ALL_CHAPTERS.length; i++)
            if (chapterSelected === ALL_CHAPTERS[i].name)
                setPageSubtitle([...pageSubtitle, `${ALL_CHAPTERS[i].tags[0]} - `, `${chapterSelected}`])
        if (chapterSelected) setCatOptions(createCategoriesList());
        setWeaponTags(createWeaponTagList())
        setEventTags(createEventTagList())
        setWeaponValue(null)
        setCatValue(null)
        setEveValue([])
        setEventsValue(null)
        setweaponCount(1)
        setIterationCount(1)
    }, [chapterSelected])
    useEffect(() => {
        if (quickSetClicked){
            const tempWep = tempWeaponValue;
            const tempEvents = tempEventsValue;
            const tempCat = tempCatValue;
            const tempEve = tempEveValue;
            setWeaponValue(tempWep);
            setEventsValue(tempEvents);
            setCatValue(tempCat);
            setEveValue(tempEve);
        }
        else{
            setTempWeaponValue(null);
            setTempEventsValue(null);
            setTempCatValue(null);
            setTempEveValue([]);
        }
    }, [quickSetClicked])
    useEffect(() => {
        const doNothing = () => { return }
        doNothing()
    }, [pageSubtitle])
    useEffect(() => {
        setEveOptions(createEventList());
    }, [catValue])
    useEffect(() => {
        setWeaponOptions(createWeaponsList());
    }, [weaponTags])
    useEffect(() => {
        setEventsOptions(createEventTagsList());
    }, [eventTags])
    useEffect(() => {
        setEventTags(createEventTagList())
    }, [weaponValue])
    useEffect(() => {
        setCatOptions(createCategoriesList());
    }, [eventsValue, weaponValue])
    useEffect(() => {
        set10Added(false)
        if (eveValue.length && chapterSelected){
            calculate(is10Added)
        }
    }, [weaponCount, iterationCount, eveValue])
    useEffect(() => {
        if (quickSetClicked) {
            setQuickSetClicked(false);
        }
        if (eveValue.length > 1)
            setIsEventsMulti(true)
        else
            setIsEventsMulti(false)
    }, [eveValue])
    useEffect(() => {
        if (weaponOptions.length === 1)
            setWeaponValue(weaponOptions[0])
    }, [weaponOptions])
    useEffect(() => {
        if (eventsOptions.length === 1)
            setEventsValue(eventsOptions[0])
    }, [eventsOptions])
    useEffect(() => {
        if (catOptions.length === 1)
            setCatValue(catOptions[0]);
    }, [catOptions])
    useEffect(() => {
        if (eveOptions.length === 1 && eveOptions[0].value != "You shouldn't see this")
            setEveValue([eveOptions[0]])
        else if (!quickSetClicked && !tempEveValue)
            setEveValue([]);
    }, [eveOptions])
    // useEffect(() => {
    //     setIsMobileDrawerShowing(false)
    // }, [isMobileDrawerShowing])

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const currentCalcs: { [charRep : string]: IStrategy[] }[] = [];

    let classAdd = "";
    if (tooSmall) classAdd = "small";
    let classTallAdd = "";
    if (tooTall && tooSmall) classTallAdd = "tall";
    if (tooShort && tooSmall) classTallAdd = "short";

    if ((screenWidth < 1250 || screenHeight < 700) && !tooSmall) {
        setTooSmall(true);
        setChapterSelected(undefined);
    }
    else if (screenWidth > 1250 && screenHeight > 700 && tooSmall) {
        setTooSmall(false);
    }

    if (screenWidth < 361 && !tooNarrow) {
        setTooNarrow(true);
    }
    else if (screenWidth > 361 && tooNarrow) {
        setTooNarrow(false);
    }

    if (screenHeight > 1000 && !tooTall) {
        setTooTall(true);
    }
    else if (screenHeight < 1000 && tooTall) {
        setTooTall(false);
    }

    if (screenHeight < 880 && !tooShort) {
        setTooShort(true);
    }
    else if (screenHeight > 880 && tooShort) {
        setTooShort(false);
    }
    
    const calculate = (is10: boolean) => { 
        const Dodics: { [charRep : string]: IStrategy[] } = {};
        const elements: ReactElement[] = [];
        for (let i = 0; i < ALL_CATEGORIES.length; i++)
            if (chapterSelected) if (ALL_CATEGORIES[i].chapters.includes(chapterSelected) && ALL_CATEGORIES[i].name === catValue?.value)
                for (let x = 0; x < ALL_CATEGORIES[i].events.length; x++)
                    for (let z = 0; z < eveValue.length; z++)
                        if (eveValue[z].label === ALL_CATEGORIES[i].events[x].name)
                            for (let y = 0; y < ALL_CATEGORIES[i].events[x].strategies.length; y++){
                                if (Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep] === undefined){
                                    Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep] = [];
                                    Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep].push({charRep:ALL_CATEGORIES[i].events[x].strategies[y].charRep, count: 0 })
                                }
                                if(!is10)
                                    Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep].push(
                                        {charRep: ALL_CATEGORIES[i].events[x].strategies[y].charRep,
                                         count: (Math.ceil(ALL_CATEGORIES[i].events[x].strategies[y].count*weaponCount*iterationCount))})
                                else
                                    Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep].push(
                                        {charRep: ALL_CATEGORIES[i].events[x].strategies[y].charRep,
                                         count: (Math.ceil(ALL_CATEGORIES[i].events[x].strategies[y].count*weaponCount*iterationCount*1.1))})
                            }
        for (const key in Dodics){
            for (let i = 0; i < ARMY_DODICS.length; i++)
                if (Dodics[key][0].charRep === ARMY_DODICS[i].charRep){
                    let sum = 0;
                    for (let x = 1; x < Dodics[key].length; x++)
                        sum += Dodics[key][x].count;
                    if (sum || Dodics[key][0].count != 0){
                    elements.push(
                        <TableRow>
                            <TableCell>{(Math.ceil(sum)).toLocaleString()}</TableCell>
                            <TableCell>{ARMY_DODICS[i].ammoName}</TableCell>
                            <TableCell>{ARMY_DODICS[i].charRep}</TableCell>
                        </TableRow>
                    )
                    }
                }
        }
        currentCalcs.push(Dodics);
        return(elements)
    }

    function quickSet(weaponChoice: Option | null, eventsChoice: Option | null, catChoice: Option | null, eveChoices: Option[]): void {
        setTempWeaponValue(weaponChoice)
        setTempEventsValue(eventsChoice)
        setTempCatValue(catChoice)
        setTempEveValue(eveChoices)
        setQuickSetClicked(true);
    }

    function titleMaker() {
        const title: ReactElement = 
        <List dense={true}>
            <ListItem>{chapterSelected} - {catValue?.value}</ListItem>
            <ListItem sx= {{borderBottom: "2px solid #bababa"}}>{weaponCount} Weapon(s) x {iterationCount} Iteration(s)</ListItem>
            {eveValue.map((chapter, i) => (
                <ListItem key={i}>{chapter.value}</ListItem>
            ))}
        </List>
        setSavedTitles([...savedTitles, title]);
    }

    function fillTextFields(form: PDFForm, pages: PDFPage[], exportableCalculations: IStrategy[]): void {
        const dodicOrder: number[] = [];
        
        for (let i = 0; i < exportableCalculations.length; i++)
            for (let x = 0; x < ARMY_DODICS.length; x++)
                if (exportableCalculations[i].charRep === ARMY_DODICS[x].charRep)
                    dodicOrder.push(x)

        for (let i = 0; i < exportableCalculations.length && i < (MAX_PAGES * 20 + 7); i++){
            if (exportableCalculations[i]){
                form.getTextField(('item.'+(i+1).toString())).setText(((i+1).toString()))
                form.getTextField(('dodic.'+(i+1).toString())).setText(exportableCalculations[i].charRep)
                form.getTextField(('nsn.'+(i+1).toString())).setText(ARMY_DODICS[dodicOrder[i]].nsn)
                form.getTextField(('name.'+(i+1).toString())).setText(ARMY_DODICS[dodicOrder[i]].nomenclature)
                form.getTextField(('amount.'+(i+1).toString())).setText((exportableCalculations[i].count.toString()))
            }
        }
        form.getTextField('5.page.left').setText('1')
        form.getTextField('5.page.right').setText(pages.length.toString())
        for (let i = 1; i < pages.length; i++){
            form.getTextField(('page.left.'+(i).toString())).setText((i+1).toString())
            form.getTextField(('page.right.'+(i).toString())).setText(pages.length.toString())
        }
    }
    
    async function modifyPdf() {
        const pdfData = await fetch('./DA581.pdf').then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(pdfData, { ignoreEncryption: true });
        const calculatedTotalsExportable: IStrategy[] = [];
        const sortedCalcs: {[charRep: string]: IStrategy[]} = {};
                
        for (let i = 0; i < savedCalcs.length; i++)
            for (const key in savedCalcs[i]){
                if (sortedCalcs[key] === undefined)
                    sortedCalcs[key] = [];
                let sum = 0;
                for (let z = 1; z < savedCalcs[i][key].length; z++){
                    sum += savedCalcs[i][key][z].count;
                }
                sortedCalcs[key].push({charRep: savedCalcs[i][key][0].charRep, count: sum})
            }
        for (const key in sortedCalcs){
            let sum = 0;
            for (let i = 0; i < sortedCalcs[key].length; i++)
                sum += sortedCalcs[key][i].count;
            calculatedTotalsExportable.push({charRep: key, count: sum})
        }

        if(calculatedTotalsExportable.length > 7){
            const pagesToGo = Math.ceil((calculatedTotalsExportable.length-7)/20);
            const pdfData2 = await fetch('./DA5811.pdf').then(res => res.arrayBuffer());
            const pdfDoc2 = await PDFDocument.load(pdfData2, { ignoreEncryption: true });
            for(let i = 0; i < pagesToGo && i < MAX_PAGES; i++) {
                const [pageTwo] = await pdfDoc.copyPages(pdfDoc2, [0])
                pdfDoc.insertPage((i+1), pageTwo);
            }

        }
        const form = pdfDoc.getForm()
        pdfDoc.registerFontkit(fontKit);
        const pages = pdfDoc.getPages()
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const cursiveFontData = await fetch('./Allura-Regular.ttf').then(res => res.arrayBuffer());
        const cursiveFont = await pdfDoc.embedFont(cursiveFontData);

        createNonFunctionalFields(form, helveticaFont, pages, cursiveFont);
        createFunctionalTextFields(form, helveticaFont, pages);
        if(calculatedTotalsExportable.length > 7) createSecondaryPagesTextFields(form, helveticaFont, pages, calculatedTotalsExportable.length);
        fillTextFields(form, pages, calculatedTotalsExportable);

        try{
        const pdfBytes = await pdfDoc.save({ });
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
        }
        catch{
            return 1;
        }
    }
    
    function Header() {
        let chapIndex = (ALL_CHAPTERS.length - 1);
        for (let i = 0; i < ALL_CHAPTERS.length; i++)
            if (chapterSelected === ALL_CHAPTERS[i].name){
                chapIndex = i;
                break;
            }
        return(
                <div className={`header${classAdd}`}>
                    <span style={{ color: "white", right: "0", position: "absolute"}}>V 1.1</span>
                    {!tooNarrow &&
                        <>
                            {!tooSmall &&
                            <img className= "ammoicon"src="ammo_op.png" alt="ammoop" />
                            }
                            {tooSmall && !chapterSelected &&
                            <img className= {`ammoicon${classAdd}`}src="ammo_op.png" alt="ammoop" />
                            }
                            {tooSmall && chapterSelected &&
                            <img className= {`ammoicon${classAdd}`}src="ammooplogo.png" alt="ammooplogo" />
                            }
                        </>
                    }
                    {tooNarrow && !chapterSelected &&
                        <img className= {`ammoicon${classAdd}`}src="ammo_op.png" alt="ammoop" />
                    }
                    {chapterSelected &&
                        <>
                            {!tooSmall && !tooNarrow &&
                                <><Button variant="contained" sx={{float: "left", marginLeft: "10px", position: "absolute", height: "fit-content", width: "fit-content", top: "22px",
                                    backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}} onClick={() => {setChapterSelected(undefined)}}
                                    startIcon={<ArrowBackIcon />}>Change
                                </Button>
                                <p style={{position: "absolute", left: "150px", top: "12px"}}>
                                    <span style={{color: "white"}}>{`${pageSubtitle[0]}`}</span>
                                    <span style={{color: ALL_CHAPTERS[chapIndex].color}}>{`${pageSubtitle[1]}`}</span>
                                </p>
                                </>
                            }
                            {tooSmall && !tooNarrow &&
                                <><Button variant="contained" sx={{float: "left", marginLeft: "10px", position: "absolute", height: "fit-content", width: "fit-content", top: "26px",
                                    backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}} onClick={() => {setChapterSelected(undefined)}}
                                    startIcon={<ArrowBackIcon />}>Change
                                </Button>
                                <div style={{position: "absolute", left: "15px", bottom: "8px"}}>
                                    <span style={{color: ALL_CHAPTERS[chapIndex].color}}>{`${pageSubtitle[1]}`}</span>
                                </div>
                                </>
                            }
                            {tooNarrow &&
                                <><Button variant="contained" sx={{float: "left", marginLeft: "10px", position: "absolute", height: "fit-content", width: "fit-content", top: "15px",
                                    backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'
                                    }} onClick={() => { setChapterSelected(undefined); } }startIcon={<ArrowBackIcon />}>Change</Button>
                                <div style={{position: "absolute", left: "15px", bottom: "4px"}}>
                                    <span style={{color: ALL_CHAPTERS[chapIndex].color}}>{`${pageSubtitle[1]}`}</span>
                                </div>
                                </>
                            }
                        </>
                    }    
                    
                    {/* Route buttons to the edit page(s) below */}
                    {/* <Button variant="contained" sx={{overflow: "initial", width: "fit-content", position: "absolute", top: "22px", right: "230px", height: "fit-content",
                    float: "right", borderRadius: "5px", backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}}
                    href="/edit">+ UPDATE STRAC</Button>
                    <Button variant="contained" sx={{overflow: "initial", width: "fit-content", position: "absolute", top: "22px", right: "20px", height: "fit-content",
                    float: "right", borderRadius: "5px", backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}}
                    href="/edit">+ NEW TRAINING</Button> */}
                </div>
        )
    }
    
    function MainContent() {
        function getCombatArms() {
            const sorted_chapters = []
            for (let i = 0; i < ALL_CHAPTERS.length; i++)
                if (ALL_CHAPTERS[i].tags.includes("Combat Arms"))
                    sorted_chapters.push(ALL_CHAPTERS[i])
            return(sorted_chapters)
        }
        
        function getCSS() {
            const sorted_chapters = []
            for (let i = 0; i < ALL_CHAPTERS.length; i++)
                if (ALL_CHAPTERS[i].tags.includes("Combat (Service) Support"))
                    sorted_chapters.push(ALL_CHAPTERS[i])
            return(sorted_chapters)
        }
    
        function getMisc() {
            const sorted_chapters = []
            for (let i = 0; i < ALL_CHAPTERS.length; i++)
                if (ALL_CHAPTERS[i].tags.includes("Special Use Case"))
                    sorted_chapters.push(ALL_CHAPTERS[i])
            return(sorted_chapters)
        }

        function givePlaceholder(bool: boolean) {
            if(bool)
                return "Multiple Selections"
            else
                return "Select Training Exercises..."
        }
    
        const combatChapters = getCombatArms()
        const cssChapters = getCSS()
        const miscChapters=getMisc()
        const Option = (props: any) => { return ( <div> <components.Option {...props}> <input type="checkbox" checked={props.isSelected} onChange={() => null} /> 
        <label>{props.value}</label> </components.Option> </div> ); }; 
    
        function buttonCreator(sorted_chapters: IChapter[]) {
            const elements: ReactElement[] = [];
                for (let i = 0; i < sorted_chapters.length; i++){
                    if (sorted_chapters[i].image && !tooSmall)
                        elements.push(
                            <div className="btndiv">
                                <IconButton sx={{    
                                    display: "flex", width: "78px", height: "78px", "&:hover": { width: "87px", height: "87px" }, flexDirection: "column", alignItems: "center", gap: "8px",
                                    borderRadius: "8px", border: "1px solid #D49F00", color: "black", backgroundColor: "#EFEFEF", fontSize: "10px",
                                    marginLeft: "auto", marginRight: "auto", backgroundImage: `url(${sorted_chapters[i].image})`, backgroundSize: 'contain'
                                    }}onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>
                                </IconButton>
                                <div className="branchname" onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>{sorted_chapters[i].name}</div>
                            </div>
                        );
                    else if (sorted_chapters[i].image && tooSmall)
                        elements.push(
                            <div className="btndiv">
                                <IconButton sx={{    
                                    display: "flex", width: "78px", height: "78px", "&:hover": { border: "2px solid #FDD017" }, flexDirection: "column", alignItems: "center", gap: "8px",
                                    borderRadius: "8px", border: "1px solid #D49F00", color: "black", backgroundColor: "#EFEFEF", fontSize: "10px",
                                    marginLeft: "auto", marginRight: "auto", backgroundImage: `url(${sorted_chapters[i].image})`, backgroundSize: 'contain'
                                    }}onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>
                                </IconButton>
                                <div className="branchname" onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>{sorted_chapters[i].name}</div>
                            </div>
                        );
                    else if (!sorted_chapters[i].image && tooSmall)
                        elements.push(
                            <div className="btndiv">
                                <IconButton sx={{    
                                    display: "flex", width: "78px", height: "78px", "&:hover": { border: "2px solid #FDD017" }, flexDirection: "column", alignItems: "center", gap: "8px",
                                    borderRadius: "8px", border: "1px solid #D49F00", color: "black", backgroundColor: "#EFEFEF", fontSize: "10px",
                                    marginLeft: "auto", marginRight: "auto"}}onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>
                                    <CompressIcon/>
                                </IconButton>
                                <div className="branchname" onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>{sorted_chapters[i].name}</div>
                            </div>
                        );
                    else
                        elements.push(
                            <div className="btndiv">
                                <IconButton sx={{    
                                    display: "flex", width: "78px", height: "78px", "&:hover": { width: "87px", height: "87px" }, flexDirection: "column", alignItems: "center", gap: "8px",
                                    borderRadius: "8px", border: "1px solid #D49F00", color: "black", backgroundColor: "#EFEFEF", fontSize: "10px",
                                    marginLeft: "auto", marginRight: "auto"}}onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>
                                    <CompressIcon/>
                                </IconButton>
                                <div className="branchname" onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>{sorted_chapters[i].name}</div>
                            </div>
                        );
                }
            return(elements)
        }

        function mobileCart(): ReactElement[] {
            const elements: ReactElement[] = [];

            function tableMaker(calcs: { [charRep : string]: IStrategy[] }) {
                const tables: ReactElement[] = [];
                
                    for (const key in calcs){
                        for (let x = 0; x < ARMY_DODICS.length; x++)
                            if (calcs[key][0].charRep === ARMY_DODICS[x].charRep){
                                let sum = 0;
                                for (let z = 1; z < calcs[key].length; z++){
                                    sum += calcs[key][z].count;
                                }
                                tables.push(
                                    <TableRow sx={{ maxWidth:'95%' }}>
                                        <TableCell sx={{ padding:'0' }} component="th" scope="row">
                                            {sum.toLocaleString()}
                                        </TableCell>
                                        <TableCell sx={{ padding:'1' }}>{ARMY_DODICS[x].ammoName}</TableCell>
                                        <TableCell sx={{ padding:'1' }} align="left">{ARMY_DODICS[x].charRep}</TableCell>
                                    </TableRow>
                                )
                            }
                    }
                return (tables);
            }
            
            for (let i = 0; i < savedCalcs.length; i++){
                elements.push(
                <><TableHead>
                    <TableRow sx={{ maxWidth:'95%' }}>
                        <TableCell sx={{ fontWeight: "bold", padding: '1' }}>
                            <IconButton  onClick={() => setOpen(!open)} sx={{ zIndex: 10 }}>
                                {open ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
                            </IconButton>
                            {savedTitles[i]}
                        </TableCell>
                    </TableRow>
                </TableHead><TableRow sx={{ maxWidth:'95%' }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                        <Collapse in={open} sx={{ maxWidth: `95%` }} timeout="auto" unmountOnExit>
                            <Box sx={{ maxWidth: `95%` }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ padding:'0' }}>QTY</TableCell>
                                            <TableCell sx={{ padding:'1' }}>Ammunition</TableCell>
                                            <TableCell sx={{ padding:'1' }} align="right">DODIC </TableCell>
                                            <TableCell sx={{ padding:'1' }}>
                                                <div style={{ display: 'flex', marginTop: '25%' }}>
                                                    {/* Potential edit button for each saved calc here */}
                                                    {/* <BorderColorOutlinedIcon sx={{ fontSize: 'medium', cursor: 'pointer', "&:hover, &.Mui-focusVisible": { color: "cornflowerblue" }}} /> */}
                                                    <ClearIcon sx={{ fontSize: 'large', cursor: 'pointer',  color: "red" }} 
                                                        onClick={() => {setSavedCalcs(savedCalcs.filter(item => item !== savedCalcs[i]));
                                                            if (savedTitles.length > 1) setSavedTitles(savedTitles.slice(0, i).concat(savedTitles.slice(i + 1, savedTitles.length)))
                                                            else setSavedTitles([])}}/>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {tableMaker(savedCalcs[i])}
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow></>)
            }
            return(elements)
        }
    
        // const dataContextValue = {
        //     isMobileDrawerShowing : [isMobileDrawerShowing],
        //     setIsMobileDrawerShowing : setIsMobileDrawerShowing
        // }
    
        return(
            <div className={`maincontent${classAdd}${classTallAdd}`}>
                {tooSmall &&
                    <><div>
                        {/* <DataContext.Provider value={dataContextValue}>
                            {MobileDrawer()}
                        </DataContext.Provider> */}
                        <div className="testingMobileBanner"><span style={{color: "red"}}>TESTING PHASE</span></div>
                    </div></>
                }
                {!chapterSelected &&
                <div className="branchcontainer">
                    <div className="chapselecttitle">Select Branch</div>
                    <img src="underline.png" style={{width: "15vw"}}/>
                    <div style={{overflowY: 'visible'}}>
                        <div className="chaptertag">COMBAT ARMS</div><br/>
                        <div className={`flexbox${classAdd}`}>{buttonCreator(combatChapters)}</div>
                    </div>
                    <div style={{overflowY: 'visible'}}>
                        <div className="chaptertag">COMBAT (SERVICE) SUPPORT</div><br/>
                        <div className={`flexbox${classAdd}`}>{buttonCreator(cssChapters)}</div>
                    </div>
                    <div style={{overflowY: 'visible'}}>
                        <div className="chaptertag">SPECIAL USE CASE</div><br/>
                        <div className={`flexbox${classAdd}`}>{buttonCreator(miscChapters)}</div>
                    </div>
                </div>
                }
                {chapterSelected &&
                    <div style={{position: "relative"}}>
                    <div>
                        <p style={{fontSize: "24px", fontWeight: "500"}}>Make your Selection</p>
                        {!tooSmall && 
                        <p style={{fontSize: "16px"}}>Note some options are only available based on previous selections</p>
                        }
                    </div>
                    <div className={`dropcontainer${classAdd}`}>
                        <div className={`dropdown${classAdd}`}>
                            <Select
                                id={'weaponSelection'}
                                placeholder="Select a Weapon..."
                                isClearable
                                options={weaponOptions}
                                value={weaponValue}
                                onChange={(newValue) => {setWeaponValue(newValue), setCatValue(null), setEveValue([])}}
                                noOptionsMessage={() => null}
                                isValidNewOption={() => false}
                                blurInputOnSelect={true}
                                styles = {{
                                    //DO NOT DELETE THIS COMMENT!!!!!
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'gold' : 'grey',
                                    boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                    '&:hover': {
                                        borderColor: 'gold'
                                    }
                                    }),
                                    //DO NOT DELETE THIS COMMENT!!!!!
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    option: (styles, { isDisabled, isFocused, isSelected }) => {
                                        return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? undefined
                                            : isSelected
                                            ? '#bababa'
                                            : isFocused
                                            ? '#f5f5f5'
                                            : undefined,
                                        color: 'black',
                                        cursor: isDisabled ? 'not-allowed' : 'default',
                                    
                                        ':active': {
                                            ...styles[':active'],
                                            backgroundColor: '#bababa'
                                        },
                                        };
                                    },
                                }}
                                />
                        </div>
                        <div className={`dropdown${classAdd}`}>
                            <Select
                                id={'eventTypeSelection'}
                                placeholder="Select a Event Type..."
                                isClearable
                                options={eventsOptions}
                                value={eventsValue}
                                onChange={(newValue) => {setEventsValue(newValue), setCatValue(null), setEveValue([])}}
                                noOptionsMessage={() => null}
                                isValidNewOption={() => false}
                                blurInputOnSelect={true}
                                styles = {{
                                    //DO NOT DELETE THIS COMMENT!!!!!
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'gold' : 'grey',
                                    boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                    '&:hover': {
                                        borderColor: 'gold'
                                    }
                                    }),
                                    //DO NOT DELETE THIS COMMENT!!!!!
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    option: (styles, { isDisabled, isFocused, isSelected }) => {
                                        return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? undefined
                                            : isSelected
                                            ? '#bababa'
                                            : isFocused
                                            ? '#f5f5f5'
                                            : undefined,
                                        color: 'black',
                                        cursor: isDisabled ? 'not-allowed' : 'default',
                                    
                                        ':active': {
                                            ...styles[':active'],
                                            backgroundColor: '#bababa'
                                        },
                                        };
                                    },
                                }}/>
                        </div>
                        <div className={`dropdown${classAdd}`}>
                            <Select
                                id={'catSelection'}
                                placeholder="Select a Category..."
                                isClearable
                                options={catOptions}
                                value={catValue}
                                onChange={(newValue) => {setCatValue(newValue), setEveValue([])}}
                                noOptionsMessage={() => null}
                                isValidNewOption={() => false}
                                blurInputOnSelect={true}
                                styles = {{
                                    //DO NOT DELETE THIS COMMENT!!!!!
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'gold' : 'grey',
                                    boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                    '&:hover': {
                                        borderColor: 'gold'
                                    }
                                    }),
                                    //DO NOT DELETE THIS COMMENT!!!!!
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    option: (styles, { isDisabled, isFocused, isSelected }) => {
                                        return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? undefined
                                            : isSelected
                                            ? '#bababa'
                                            : isFocused
                                            ? '#f5f5f5'
                                            : undefined,
                                        color: 'black',
                                        cursor: isDisabled ? 'not-allowed' : 'default',
                                    
                                        ':active': {
                                            ...styles[':active'],
                                            backgroundColor: '#bababa'
                                        },
                                        };
                                    },
                                }}/>
                        </div>
                        <div className={`dropdown${classAdd}`}>
                            <Select 
                                id={'trainingSelection'}
                                components={{ Option }}
                                placeholder={givePlaceholder(isEventsMulti)}
                                isClearable
                                options={eveOptions}
                                value={eveValue}
                                onChange={(newValue) => {setEveValue(newValue)}}
                                noOptionsMessage={() => null}
                                isValidNewOption={() => false}
                                isDisabled = {Boolean(!catValue)}
                                isMulti
                                controlShouldRenderValue={!isEventsMulti}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                styles = {{
                                    //DO NOT DELETE THIS COMMENT!!!!!
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? 'gold' : 'grey',
                                        boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                        '&:hover': {
                                            borderColor: 'gold'
                                        }
                                    }),
                                    //DO NOT DELETE THIS COMMENT!!!!!
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    option: (styles, { isDisabled, isFocused, isSelected }) => {
                                        return {
                                            ...styles,
                                            backgroundColor: isDisabled
                                            ? undefined
                                            : isSelected
                                            ? '#bababa'
                                            : isFocused
                                            ? '#f5f5f5'
                                            : undefined,
                                            color: 'black',
                                            cursor: isDisabled ? 'not-allowed' : 'default',
                                            
                                            ':active': {
                                                ...styles[':active'],
                                                backgroundColor: '#bababa'
                                            },
                                        };
                                    },
                                    //DO NOT DELETE THIS COMMENT!!!!!
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    placeholder: defaultStyles => {
                                        return {
                                        ...defaultStyles,
                                        color: isEventsMulti ? '#333333' : '#808080'
                                        };
                                    }
                                }}
                                />
                        </div>
                        <div className="iterator" >
                            <div>Weapon Count</div>
                            <Iterator onChange={setweaponCount} currValue={weaponCount} />
                            <div>Iteration Count</div>
                            <Iterator onChange={setIterationCount} currValue={iterationCount} />
                        </div>
                    </div>
                    {!(eveValue.length) &&
                    <div className={`calccontainer${classAdd}`}>
                        Results
                        <div className="resultcontainer">
                            <div className="resultinnercontainer" >
                                <TableContainer component={Paper} sx={{ zIndex: 3, backgroundColor: "#f5f5f5" }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight: "bold"}}>QTY</TableCell>
                                                <TableCell sx={{fontWeight: "bold"}}>Ammunition</TableCell>
                                                <TableCell sx={{fontWeight: "bold"}}>DODIC</TableCell>
                                            </TableRow>
                                        </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{cursor: "default"}}>{"ㅤㅤ"}</TableCell>
                                                    <TableCell sx={{cursor: "default"}}>{"ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ"}</TableCell>
                                                    <TableCell sx={{cursor: "default"}}>{"ㅤㅤㅤ"}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        <div className="calcbtncontainer">
                            <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}}
                                onClick={() => {setWeaponValue(null); setEventsValue(null); setCatValue(null); setEveValue([]); setweaponCount(1); setIterationCount(1)}}>ADD TO CART</Button>
                            {/* <Button sx={{backgroundColor: !is10Added ? "#f5f5f5":"#bababa", color: !is10Added ? 'black':'grey',
                                "&:hover, &.Mui-focusVisible": !is10Added ? { backgroundColor: "#f5f5f5" } : { backgroundColor: "#bababa" }, 
                                height: '32px', borderRadius: "5px"}}>ADD 10%</Button> */}
                        </div>
                    </div>
                    }
                    {(eveValue.length > 0) &&
                    <div className={`calccontainer${classAdd}`}>
                        Results
                        <div className="resultcontainer">
                            <div className="resultinnercontainer" >
                                <TableContainer component={Paper} sx={{ zIndex: 3, backgroundColor: "#f5f5f5" }}>
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight: "bold"}}>QTY</TableCell>
                                                <TableCell sx={{fontWeight: "bold"}}>Ammunition</TableCell>
                                                <TableCell sx={{fontWeight: "bold"}}>DODIC</TableCell>
                                            </TableRow>
                                        </TableHead>
                                            <TableBody>
                                                {calculate(is10Added)}
                                            </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        <div className="calcbtncontainer">
                            <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}}
                                onClick={() => { setSavedCalcs([...savedCalcs, currentCalcs[0]]); titleMaker(); setWeaponValue(null); setEventsValue(null); setCatValue(null);
                                setEveValue([]); setweaponCount(1); setIterationCount(1); }}>ADD TO CART</Button>
                            {/* <Button sx={{backgroundColor: !is10Added ? "#f5f5f5":"#bababa", color: !is10Added ? 'black':'grey',
                                "&:hover, &.Mui-focusVisible": !is10Added ? { backgroundColor: "#f5f5f5" } : { backgroundColor: "#bababa" }, 
                                height: '32px', borderRadius: "5px"}} onClick={() => {set10Added(!is10Added)}}>ADD 10%</Button> */}
                        </div>
                    </div>
                    }
                    <div className={`quickbox${classAdd}`}>
                        <p>Quick Requistion Links</p>
                        {quickLinkMaker({label: 'M4', value: 'M4'}, {label: 'Qualification', value: 'Qualification'},
                            {label: 'M16/M4 Qualification', value: 'M16/M4 Qualification'}, [{label: 'Zero/Group/KD', value: 'Zero/Group/KD'},
                            {label: 'Practice', value: 'Practice'}, {label: 'Day Qualification', value: 'Day Qualification'}], 'M4 Qualification')}
                        {quickLinkMaker({label: 'M4', value: 'M4'}, {label: 'Collective Training', value: 'Collective Training'},
                            {label: 'M16/M4 Collective Training', value: 'M16/M4 Collective Training'}, [{label: 'Sqd/Plt LFX', value: 'Sqd/Plt LFX'},
                            {label: 'Sqd/Plt/Co LFX (Blank)', value: 'Sqd/Plt/Co LFX (Blank)'}], 'M4 Collective Training')}
                        {quickLinkMaker({label: 'M240', value: 'M240'}, {label: 'Qualification', value: 'Qualification'},
                            {label: 'M240 Qualification', value: 'M240 Qualification'}, [{label: '10m Zero', value: '10m Zero'},
                            {label: '10m Practice', value: '10m Practice'}, {label: '10m Record', value: '10m Record'}], 'M240 Qualification')}
                        {quickLinkMaker({label: 'M240', value: 'M240'}, {label: 'Collective Training', value: 'Collective Training'},
                            {label: 'M240 MG Collective Training', value: 'M240 MG Collective Training'}, [{label: 'Sqd/Plt LFX', value: 'Sqd/Plt LFX'},
                            {label: 'Sqd/Plt/Co LFX (Blank)', value: 'Sqd/Plt/Co LFX (Blank)'}], 'M240 Collective Training')}
                        {quickLinkMaker({label: 'M249', value: 'M249'}, {label: 'Qualification', value: 'Qualification'},
                            {label: 'M249 Qualification', value: 'M249 Qualification'}, [{label: '10m Zero', value: '10m Zero'},
                            {label: '10m Practice', value: '10m Practice'}, {label: '10m Record', value: '10m Record'}], 'M249 Qualification')}
                        {quickLinkMaker({label: 'M249', value: 'M249'}, {label: 'Collective Training', value: 'Collective Training'},
                            {label: 'M249 Collective Training', value: 'M249 Collective Training'}, [{label: 'Sqd/Plt LFX', value: 'Sqd/Plt LFX'},
                            {label: 'Sqd/Plt/Co LFX (Blank)', value: 'Sqd/Plt/Co LFX (Blank)'}], 'M249 Collective Training')}
                        {quickLinkMaker(null, {label: 'JRTC', value: 'JRTC'}, {label: 'JRTC IBCT Force On Force', value: 'JRTC IBCT Force On Force'}, 
                            [{label: 'Force on Force', value: 'Force on Force'}], 'JRTC IBCT Force on Force')}
                        {quickLinkMaker(null, {label: 'JRTC', value: 'JRTC'}, {label: 'JRTC IBCT Live Fire', value: 'JRTC IBCT Live Fire'}, 
                            [{label: 'Live Fire', value: 'Live Fire'}], 'JRTC IBCT Live Fire')}
                        {quickLinkMaker(null, {label: 'NTC', value: 'NTC'}, {label: 'NTC ABCT Force On Force', value: 'NTC ABCT Force On Force'}, 
                            [{label: 'Force on Force', value: 'Force on Force'}], 'NTC ABCT Force on Force')}
                        {quickLinkMaker(null, {label: 'NTC', value: 'NTC'}, {label: 'NTC ABCT Live Fire', value: 'NTC ABCT Live Fire'}, 
                            [{label: 'Live Fire', value: 'Live Fire'}], 'NTC ABCT Live Fire')}
                        {quickLinkMaker(null, {label: 'NTC', value: 'NTC'}, {label: 'NTC SBCT Force On Force', value: 'NTC SBCT Force On Force'}, 
                            [{label: 'Force on Force', value: 'Force on Force'}], 'NTC SBCT Force on Force')}
                        {quickLinkMaker(null, {label: 'NTC', value: 'NTC'}, {label: 'NTC SBCT Live Fire', value: 'NTC SBCT Live Fire'}, 
                            [{label: 'Live Fire', value: 'Live Fire'}], 'NTC SBCT Live Fire')}
                        {quickLinkMaker(null, {label: 'JMRC', value: 'JMRC'}, {label: 'JMRC IBCT Force On Force', value: 'JMRC IBCT Force On Force'}, 
                            [{label: 'Force on Force', value: 'Force on Force'}], 'JMRC IBCT Force on Force')}
                        {quickLinkMaker(null, {label: 'JMRC', value: 'JMRC'}, {label: 'JMRC IBCT Live Fire', value: 'JMRC IBCT Live Fire'}, 
                            [{label: 'Live Fire', value: 'Live Fire'}], 'JMRC IBCT Live Fire')}
                        {quickLinkMaker(null, {label: 'JMRC', value: 'JMRC'}, {label: 'JMRC ABCT Force On Force', value: 'JMRC ABCT Force On Force'}, 
                            [{label: 'Force on Force', value: 'Force on Force'}], 'JMRC ABCT Force on Force')}
                        {quickLinkMaker(null, {label: 'JMRC', value: 'JMRC'}, {label: 'JMRC ABCT Live Fire', value: 'JMRC ABCT Live Fire'}, 
                            [{label: 'Live Fire', value: 'Live Fire'}], 'JMRC ABCT Live Fire')}
                        {quickLinkMaker(null, {label: 'JMRC', value: 'JMRC'}, {label: 'JMRC SBCT Force On Force', value: 'JMRC SBCT Force On Force'}, 
                            [{label: 'Force on Force', value: 'Force on Force'}], 'JMRC SBCT Force on Force')}
                    </div>
                    {(savedCalcs.length > 0) &&
                    <>
                        {tooSmall && 
                        <div className="mobilecart">
                            {mobileCart()}
                            <div className="cartbtndivsmall">
                                <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                    height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}} onClick={() => {setSavedCalcs([]); setSavedTitles([])}}>Clear</Button>
                                <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                    height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}} onClick={() => {modifyPdf()}}><FileUploadOutlinedIcon/>Export</Button>
                            </div>
                        </div>
                        }
                    </>
                    }
                </div>
                }
            </div>
        )
    }

    function Description() {
        let step1 = false;
        let step2 = false;
        let step3 = false;
        let step4 = false;
        if(eveValue.length > 0){step1 = true; step2 = true; step3 = true; step4 = true;}
            else if(catValue){step1 = true; step2 = true; step3 = true;}
            else if(eventsValue){step1 = true; step2= true;}
            else if(weaponValue){step1 = true;}
        return(
            <>{!tooSmall &&
            <div className="description">
                {chapterSelected &&
                    <><div className="descriptionInnerBox">
                    <h4 style={{marginTop: "0px"}}>Steps</h4><br/>
                    <div style={{fontWeight: "bold"}}>*<span style ={{color: "red"}}>This Project is in a TESTING PHASE. Any issues or bugs found should be detailed in an 
                            email to the project manager found at the "contact us" link at the bottom of the webpage.</span>*</div><br/>
                    <ul style={{textAlign: "left"}}>
                        {step1 &&
                        <><CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select a Weapon Type<br/><br/></>
                        }
                        {!step1 &&
                        <><CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select a Weapon Type<br/><br/></>
                        }
                        {step2 &&
                        <><CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select an Event Type<br/><br/></>
                        }
                        {!step2 &&
                        <><CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select an Event Type<br/><br/></>
                        }
                        {step3 &&
                        <><CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select a Category<br/><br/></>
                        }
                        {!step3 &&
                        <><CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select a Category<br/><br/></>
                        }
                        {step4 &&
                        <><CheckBoxOutlinedIcon sx={{fontSize: 'medium'}} />Select all Training Exercises<br/><br/><br/></>
                        }
                        {!step4 &&
                        <><CheckBoxOutlineBlankOutlinedIcon sx={{fontSize: 'medium'}} />Select all Training Exercises<br/><br/><br/></>
                        }
                        <li>Add Weapon Count</li><br/>
                        <li>Add Number of Iterations</li><br/>
                        <li>Click "Add to Cart"</li><br/>
                        <li>Repeat as Needed</li>
                    </ul>
                    <br/>
                    <div className="linkdiv" style={{position: "absolute", width: '100%'}}>
                        <a href="https://www.dau.edu/sites/default/files/Migrated/CopDocuments/CAPULDI%202009.pdf"
                        target="_blank">CAPULDI 2009</a><br/>
                        <a href="https://www.dau.edu/sites/default/files/2023-11/CAPULDI%20excel%2030%20November%202023.xlsx"
                        target="_blank">CAPULDI 2023 EXCEL</a><br/>
                        <a href="https://www.dau.edu/cop/ammo/page/AEResearchTools"
                        target="_blank">YELLOW BOOK</a>
                    </div>
                </div>
                <div className="descriptionButtonDiv">
                    <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                            height: '32px', marginLeft: '10px', left: '0px', color: 'white', borderRadius: "5px"}}
                            onClick={() => {setModalOpen(!isModalOpen)}}>Contact Us</Button>
                </div></>
                }
                {!chapterSelected &&
                    <><div className="descriptionInnerBox">
                        <h4 style={{marginTop: "0px"}}>What is AmmoOp?</h4><br/>
                        <div style={{fontWeight: "bold"}}>*<span style ={{color: "red"}}>This Project is in a TESTING PHASE. Any issues or bugs found should be detailed in an 
                            email to the project manager found at the "contact us" link at the bottom of the webpage.</span>*</div><br/>
                        <div>AmmoOp is designed to simplify the process of ordering ammunition for individuals of any rank or position. It automatically 
                            calculates all ammunition requirements based on the latest STRAC for the chosen event or series of events. In upcoming updates, 
                            we plan to introduce a feature that allows users to create custom live fire ranges. For now, we hope you find value in the 
                            current version, saving you both time and reducing stress. Enjoy using AmmoOp!</div>
                        <br/>
                        <div className="linkdiv" style={{position: "absolute", width: '100%' }}>
                        <a href="https://www.dau.edu/sites/default/files/Migrated/CopDocuments/CAPULDI%202009.pdf"
                        target="_blank">CAPULDI 2009</a><br/>
                        <a href="https://www.dau.edu/sites/default/files/2023-11/CAPULDI%20excel%2030%20November%202023.xlsx"
                        target="_blank">CAPULDI 2023 EXCEL</a><br/>
                        <a href="https://www.dau.edu/cop/ammo/page/AEResearchTools"
                        target="_blank">YELLOW BOOK</a>
                        </div>
                    </div>
                    <div className="descriptionButtonDiv">
                        <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                            height: '32px', marginLeft: '10px', left: '0px', color: 'white', borderRadius: "5px"}}
                            onClick={() => {setModalOpen(!isModalOpen)}}>Contact Us</Button>
                    </div></>
                }
                </div>}</>
        )
    }

    function Cart() {
        const elements: ReactElement[] = [];

        function tableMaker(calcs: { [charRep : string]: IStrategy[] }) {
            const tables: ReactElement[] = [];
            
                for (const key in calcs){
                    for (let x = 0; x < ARMY_DODICS.length; x++)
                        if (calcs[key][0].charRep === ARMY_DODICS[x].charRep){
                            let sum = 0;
                            for (let z = 1; z < calcs[key].length; z++){
                                sum += calcs[key][z].count;
                            }
                            tables.push(
                                <TableRow sx={{ maxWidth:'95%' }}>
                                    <TableCell sx={{ padding:'0' }} component="th" scope="row">
                                        {sum.toLocaleString()}
                                    </TableCell>
                                    <TableCell sx={{ padding:'1' }}>{ARMY_DODICS[x].ammoName}</TableCell>
                                    <TableCell sx={{ padding:'1' }} align="left">{ARMY_DODICS[x].charRep}</TableCell>
                                </TableRow>
                            )
                        }
                }
            return (tables);
        }
          
        for (let i = 0; i < savedCalcs.length; i++){
            elements.push(
            <><TableHead>
                <TableRow sx={{ maxWidth:'95%' }}>
                    <TableCell sx={{ fontWeight: "bold", padding: '1' }}>
                        <IconButton  onClick={() => setOpen(!open)} sx={{ zIndex: 10 }}>
                            {open ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
                        </IconButton>
                        {savedTitles[i]}
                    </TableCell>
                </TableRow>
            </TableHead><TableRow sx={{ maxWidth:'95%' }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} sx={{ maxWidth: `95%` }} timeout="auto" unmountOnExit>
                        <Box sx={{ maxWidth: `95%` }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ padding:'0' }}>QTY</TableCell>
                                        <TableCell sx={{ padding:'1' }}>Ammunition</TableCell>
                                        <TableCell sx={{ padding:'1' }} align="right">DODIC </TableCell>
                                        <TableCell sx={{ padding:'1' }}>
                                            <div style={{ display: 'flex', marginTop: '25%' }}>
                                                {/* Potential edit button for each saved calc here */}
                                                {/* <BorderColorOutlinedIcon sx={{ fontSize: 'medium', cursor: 'pointer', "&:hover, &.Mui-focusVisible": { color: "cornflowerblue" }}} /> */}
                                                <ClearIcon sx={{ fontSize: 'large', cursor: 'pointer', "&:hover, &.Mui-focusVisible": { color: "red" }}} 
                                                    onClick={() => {setSavedCalcs(savedCalcs.filter(item => item !== savedCalcs[i]));
                                                        if (savedTitles.length > 1) setSavedTitles(savedTitles.slice(0, i).concat(savedTitles.slice(i + 1, savedTitles.length)))
                                                        else setSavedTitles([])}}/>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {tableMaker(savedCalcs[i])}
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow></>)
        }
        return(elements)
    }

    function CartBox() {
        return(
            <>{!tooSmall &&
            <>{chapterSelected &&
                <div className="cartbox">
                    <div className="innerCartBox">
                        <div style={{fontSize: 'large', fontWeight: 'bold', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', maxWidth: '100%'}}>
                            <ShoppingCartOutlinedIcon sx={{fontSize: 'medium', fontWeight: 'bold'}}/> Ammo Requisition Count</div><br/>
                            <TableContainer component={Paper} sx={{ zIndex: 3, backgroundColor: "#f5f5f5" }}>
                                <Table sx={{backgroundColor: '#f5f5f5', maxWidth: '95%'}}>
                                    <>{Cart()}</>
                                </Table>
                            </TableContainer>
                        <div className="cartbtndiv">
                            <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}} onClick={() => {setSavedCalcs([]); setSavedTitles([])}}>Clear</Button>
                            <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}} onClick={() => {modifyPdf()}}><FileUploadOutlinedIcon/>Export</Button>
                        </div>
                    </div>    
                </div>
                }</>
            }</>
        )
    }

    function quickLinkMaker(weaponChoice: Option | null, eventsChoice: Option | null, catChoice: Option | null, eveChoices: Option[], linkName: string) {
        let weaponCheck = false;
        let eventsCheck = false;
        let catCheck = false;

        if (weaponChoice){ if(weaponOptions.some((x) => x.label === weaponChoice.label)) weaponCheck = true;} else weaponCheck = true;
        if (eventsChoice){ if(eventsOptions.some((x) => x.label === eventsChoice.label)) eventsCheck = true;} else eventsCheck = true;
        if (catChoice){ if(catOptions.some((x) => x.label === catChoice.label)) catCheck = true;} else catCheck = true;

        if (weaponCheck && eventsCheck && catCheck)
            return(
                <Button sx={{backgroundColor: "#bababa", "&:hover, &.Mui-focusVisible": { backgroundColor: "#bababa" },
                height: '2vh', width: 'fit-content', marginLeft: '1vw', color: 'black', borderRadius: "5px", fontSize: '12px',
                marginBottom: '1vw', float: 'left'}} onClick={() => {quickSet(weaponChoice, eventsChoice, catChoice, eveChoices);}}>
                {linkName}</Button>
            )
        else return null;
    }
    
    function Footer() {
        return(
            <div className={`footer${classAdd}${classTallAdd}`}>
                {!tooNarrow && 
                    <img src="logowithtext.png" className={`footerimage${classAdd}`} alt="footerimage"/>
                }
                    {tooSmall && 
                    <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                        height: '32px', marginLeft: '10px', float: 'left', color: 'white', borderRadius: "5px", position: "absolute", bottom: "calc(4.5vh - 16px)"}}
                        onClick={() => { setModalOpen(!isModalOpen); } }>Contact Us
                    </Button>
                    }
            </div>
        )
    }

    // const dataContextValue = {
    //     isMobileDrawerShowing : [isMobileDrawerShowing],
    //     setIsMobileDrawerShowing : setIsMobileDrawerShowing
    // }

    return(
        <div className="BETTERcontainer" style={{display: "flex", flexDirection: "row"}}>
            {/* <DataContext.Provider value={dataContextValue}> */}
            {/* </DataContext.Provider> */}
            <div className="container" style={{width: "100vw", height: "100vh", overflow: "hidden"}}>
                <>{Header()}</>
                <>{MainContent()}</>
                <>{MobileDrawer()}</>
                <>{Description()}</>
                <>{CartBox()}</>
                <>{Footer()}</>
                <Modal
                    isOpen={isModalOpen}
                    ariaHideApp={false}
                    onRequestClose={() => {setModalOpen(false)}}
                    style={MODAL_STYLE_FIT_CONTENT}
                    contentLabel="PM Information">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div>{STR_PROJECT_MANAGER_INFO}</div>
                        <a href={`mailto: ${STR_PROJECT_MANAGER_EMAIL}`}>{STR_PROJECT_MANAGER_EMAIL}</a>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default AmmoOP;