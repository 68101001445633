import React from "react";
import AmmoOP from "./ammoOP";

function Facelift(): React.ReactElement {
    return(
        <div style={{overflow: "hidden", top: "0px"}}>
            <AmmoOP />
        </div>
    )
}

export default Facelift;