import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Editor from './components/editor';
import PageNotFound from './components/pageNotFound';
import Facelift from './components/facelift';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Facelift />}></Route>
                <Route path="edit" element={<Editor />}></Route>
                <Route path="legacy" element={<App />}></Route>
                <Route path="facelift" element={<Facelift />}></Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
